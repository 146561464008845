import * as React from "react";
import "../css/inspection.css";
import { useParams } from "react-router-dom";
import analysisResults from "../data/openai_analysis.json";

const RoadDetails = ({ className, file }) => {
    const { sectionID } = useParams();
    const currentRoadAnalysis = analysisResults[sectionID];
    console.log(currentRoadAnalysis);
    return (
        <div className="application">
            <div className="backgroundContainer">
                <div className="backgroundBox">
                    <h1>Road Analysis: {sectionID}</h1>
                    <p>{currentRoadAnalysis.analysis}</p>
                </div>
                </div>
                {currentRoadAnalysis.cloudinary_images?.[0]?.map((image, index) => (
                <img
                    key={index}
                    src={image.cloudinary_url}
                    alt={image.file_name || `Road Image ${index}`}
                    style={{ width: '100%', height: 'auto'}}
                />
                ))}
        </div>
    );
};

export default RoadDetails;
