import * as React from "react";
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import "../css/inspection.css";

const ImageUpload = ({ className, file }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [responseText, setResponseText] = useState("No File Uploaded.");
    const canvasRef = useRef(null);
    const videoRef = useRef(null);
    const apiKey = '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';
    const assistantId = 'asst_sbUz4gcRzoEdcLHUJiPVXMYX';
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
        'OpenAI-Beta': 'assistants=v2',  
    };
    const delay = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    const drawPlaceholderImage = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const placeholderImg = new Image();
        placeholderImg.src = require('../images/image.png'); 
        placeholderImg.onload = () => {
            canvas.width = placeholderImg.width;
            canvas.height = placeholderImg.height;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(placeholderImg, 0, 0);
        };
    };

    const drawImage = (imageFile) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (imageFile && imageFile.type.startsWith('image/')) {
            const reader = new FileReader();
    
            reader.onload = function(e) {
                const img = new Image();
                img.src = e.target.result; 
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0);
                };
            };
            reader.readAsDataURL(imageFile);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file && file.type.startsWith('image/')) {
            drawImage(file);
        } else if (file && file.type.startsWith('video/')) {
            const videoUrl = URL.createObjectURL(file);  
            videoRef.current.src = videoUrl; 
            videoRef.current.load();
            const videoElement = videoRef.current;
            videoElement.addEventListener('loadedmetadata', () => {
                const duration = videoElement.duration;
                console.log("Video duration is " + duration + " seconds");
                extractFrames(duration)
            });
        }
    };

    const extractFrames = async (duration) => {
        const videoElement = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const frameCount = 9;  
        const framesArray = [];
        const interval = duration / frameCount;  
        let currentTime = 0;
        const frameIDsArray = [];  
    
        console.log("start extracting");
    
        const captureFrame = async (i) => {
            if (i >= frameCount) {
                console.log('All 9 frames have been uploaded!', frameIDsArray);
                handleSubmitNine(frameIDsArray); 
                return; 
            }
            videoElement.currentTime = currentTime;
            videoElement.addEventListener('seeked', async function handleSeek() {
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                const dataURL = canvas.toDataURL('image/png');
                framesArray.push(dataURL);
                const blob = await fetch(dataURL).then(res => res.blob());
                const file = new File([blob], `frame_${i}.png`, { type: 'image/png' });
                const fileId = await uploadImage(file);
                if (fileId) {
                    frameIDsArray.push(fileId);  
                }
                currentTime += interval;
                captureFrame(i + 1);
                videoElement.removeEventListener('seeked', handleSeek);
            });
        };
        captureFrame(0);
    };
    

    useEffect(() => {
        drawPlaceholderImage();
    }, [])

    useEffect(() => {
        if (selectedFile) {
            handleSubmitOne();
        }
    }, [selectedFile]);  
    
    async function uploadImage(selectedFile) {
        try {
            const formData = new FormData();
            formData.append('file', selectedFile);  
            formData.append('purpose', 'vision'); 
            const uploadResponse = await axios.post(
                'https://api.openai.com/v1/files',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${apiKey}` 
                    }
                }
            );
    
            const fileId = uploadResponse.data.id;  
            console.log('Uploaded File ID:', fileId);
            return fileId;
        } catch (error) {
            console.error('Error uploading the file:', error.response ? error.response.data : error.message);
            return null; 
        }
    }
    
    const handleSubmitOne = async () => {
        setResponseText("Loading...");
        if (!selectedFile) {
            console.log("No file selected");
            return;
        }
        const fileId = await uploadImage(selectedFile);  
        if (!fileId) {
            setResponseText('Image upload failed.');
            return;  
        }

        const requestData = {
            assistant_id: assistantId,  
            thread: {
                messages: [
                    {
                        role: 'user',  
                        content: [
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": fileId  
                                }
                            },
                            {
                                "type": "text",
                                "text": "Is this road in good condition?" 
                            }
                        ]
                    }
                ]
            }
        };
    
        try {
            const response = await axios.post(
                'https://api.openai.com/v1/threads/runs',
                requestData,
                { headers: headers }
            );
    
            const threadId = response.data.thread_id;
            const runId = response.data.id;
            let runResponse = await axios.get(
                `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                {
                    headers: headers,
                }
            );
    
            while (runResponse.data.status !== "completed") {
                runResponse = await axios.get(
                    `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                    {
                        headers: headers,
                    }
                );
                await delay(1000);  
            }
    
            const messageResponse = await axios.get(`https://api.openai.com/v1/threads/${threadId}/messages`, {
                headers: headers
            });

            if (messageResponse.data && messageResponse.data.data[0] && messageResponse.data.data[0].content[0].text) {
                const finalResponse = messageResponse.data.data[0].content[0].text.value;
                setResponseText(finalResponse);
            } else {
                console.error('Unexpected response structure:', messageResponse.data);
                setResponseText('Unexpected response structure from the assistant.');
            }
    
        } catch (error) {
            console.error('Error calling the OpenAI API:', error.response ? error.response.data : error.message);
            setResponseText('An error occurred while processing your request.');
        }
    };

    const handleSubmitNine = async (frameIDsArray) => {
        setResponseText("Loading...");
        console.log("Submitting Nine");
        const requestData = {
            assistant_id: assistantId,  
            thread: {
                messages: [
                    {
                        role: 'user',  
                        content: [
                            {
                                "type": "text",
                                "text": "All the following 9 images are from the same road, process all of them and return a report with estimates on the entire road's overall PCI score"
                            },
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": frameIDsArray[0]  
                                }
                            },
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": frameIDsArray[1] 
                                }
                            },
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": frameIDsArray[2] 
                                }
                            },
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": frameIDsArray[3]  
                                }
                            },
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": frameIDsArray[4] 
                                }
                            },
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": frameIDsArray[5] 
                                }
                            },
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": frameIDsArray[6] 
                                }
                            },
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": frameIDsArray[7] 
                                }
                            },
                            {
                                "type": "image_file",
                                "image_file": {
                                    "file_id": frameIDsArray[8] 
                                }
                            },
                        ]
                    }
                ]
            }
        };
    
        try {
            const response = await axios.post(
                'https://api.openai.com/v1/threads/runs',
                requestData,
                { headers: headers }
            );
    
            const threadId = response.data.thread_id;
            const runId = response.data.id;
            let runResponse = await axios.get(
                `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                {
                    headers: headers,
                }
            );
    
            while (runResponse.data.status !== "completed") {
                runResponse = await axios.get(
                    `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                    {
                        headers: headers,
                    }
                );
                await delay(1000);  

            }
            const messageResponse = await axios.get(`https://api.openai.com/v1/threads/${threadId}/messages`, {
                headers: headers
            });
            if (messageResponse.data && messageResponse.data.data[0] && messageResponse.data.data[0].content[0].text) {
                const finalResponse = messageResponse.data.data[0].content[0].text.value;
                setResponseText(finalResponse);
            } else {
                console.error('Unexpected response structure:', messageResponse.data);
                setResponseText('Unexpected response structure from the assistant.');
            }
    
        } catch (error) {
            console.error('Error calling the OpenAI API:', error.response ? error.response.data : error.message);
            setResponseText('An error occurred while processing your request.');
        }
    };
    
    return (
        <div className="application">
            <div className='title'>
                <h1>Road Image Analyzer</h1>
                <div className="analysis">
                    <strong>Analysis:</strong> {responseText}
                </div>
            </div>
            <div className="placeholder">
                <canvas ref={canvasRef} className="preview-image"></canvas>
                    <video
                        ref={videoRef}
                        width="600"
                        height="338"
                        style={{ display: 'none', margin: '0 auto' }}
                    ></video>
            </div>

            <div>
                <input
                    type="file"
                    id="fileUpload"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                <label htmlFor="fileUpload" className="upload-button">
                    {selectedFile ? 'Change File' : 'Upload File'}
                </label>
            </div>
        </div>
    );
};

export default ImageUpload;
