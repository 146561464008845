import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import '../css/chatbot.css'; // Import external CSS file

const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false); // ✅ Properly initialized
    const [thread, setThread] = useState(null);
    const [thinkingDots, setThinkingDots] = useState('...'); // Add this new state

    const delay = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    // ✅ Create a ref for the message container
    const messagesEndRef = useRef(null);

    // ✅ Function to scroll to the bottom
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]); // ✅ Runs when messages update

    // Add this new useEffect for the thinking animation
    useEffect(() => {
        let interval;
        if (loading) {
            interval = setInterval(() => {
                setThinkingDots((prev) => {
                    if (prev === '.') return '..';
                    if (prev === '..') return '...';
                    if (prev === '...') return '.';
                    return '.';
                });
            }, 500);
        }
        return () => clearInterval(interval);
    }, [loading]);

    const sendMessage = async () => {
        if (!input.trim() || loading) return; // ✅ Prevents sending while loading

        const newMessages = [...messages, { sender: 'user', text: input }];
        setMessages(newMessages);
        setInput('');
        setLoading(true); // ✅ Set loading to true before sending request

        try {
            if (!thread) {
                // create thread and run
                const response = await axios.post(
                    'https://api.openai.com/v1/threads/runs',
                    {
                        assistant_id: 'asst_ND8TYd95lUYbGduUucVbCyWE',
                        thread: {
                            messages: [
                                {
                                    role: 'user',
                                    content: input,
                                },
                            ],
                        },
                    },
                    {
                        headers: {
                            Authorization: `Bearer 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`,
                            'Content-Type': 'application/json',
                            'OpenAI-Beta': 'assistants=v2',
                        },
                    }
                );

                const threadId = response.data.thread_id;
                const runId = response.data.id;

                setThread(threadId);

                // retrieve run status
                let runResponse = await axios.get(
                    `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                    {
                        headers: {
                            Authorization: `Bearer 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`,
                            'Content-Type': 'application/json',
                            'OpenAI-Beta': 'assistants=v2',
                        },
                    }
                );

                while (runResponse.data.status !== 'completed') {
                    runResponse = await axios.get(
                        `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                        {
                            headers: {
                                Authorization: `Bearer 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`,
                                'Content-Type': 'application/json',
                                'OpenAI-Beta': 'assistants=v2',
                            },
                        }
                    );

                    await delay(1000);
                }

                const messageResponse = await axios.get(
                    `https://api.openai.com/v1/threads/${threadId}/messages`,
                    {
                        headers: {
                            Authorization: `Bearer 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`,
                            'Content-Type': 'application/json',
                            'OpenAI-Beta': 'assistants=v2',
                        },
                    }
                );

                const finalResponse =
                    messageResponse.data.data[0].content[0].text.value;
                setMessages([
                    ...newMessages,
                    { sender: 'bot', text: finalResponse },
                ]);
            } else {
                // thread already exists, add message
                await axios.post(
                    `https://api.openai.com/v1/threads/${thread}/messages`,
                    {
                        role: 'user',
                        content: input,
                    },
                    {
                        headers: {
                            Authorization: `Bearer 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`,
                            'Content-Type': 'application/json',
                            'OpenAI-Beta': 'assistants=v2',
                        },
                    }
                );

                // create a run
                const createRunResponse = await axios.post(
                    `https://api.openai.com/v1/threads/${thread}/runs`,
                    {
                        assistant_id: 'asst_ND8TYd95lUYbGduUucVbCyWE',
                    },
                    {
                        headers: {
                            Authorization: `Bearer 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`,
                            'Content-Type': 'application/json',
                            'OpenAI-Beta': 'assistants=v2',
                        },
                    }
                );

                const threadId = createRunResponse.data.thread_id;
                const runId = createRunResponse.data.id;

                let runResponse = await axios.get(
                    `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                    {
                        headers: {
                            Authorization: `Bearer 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`,
                            'Content-Type': 'application/json',
                            'OpenAI-Beta': 'assistants=v2',
                        },
                    }
                );

                while (runResponse.data.status !== 'completed') {
                    runResponse = await axios.get(
                        `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                        {
                            headers: {
                                Authorization: `Bearer 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`,
                                'Content-Type': 'application/json',
                                'OpenAI-Beta': 'assistants=v2',
                            },
                        }
                    );

                    await delay(1000);
                }

                const messageResponse = await axios.get(
                    `https://api.openai.com/v1/threads/${threadId}/messages`,
                    {
                        headers: {
                            Authorization: `Bearer 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`,
                            'Content-Type': 'application/json',
                            'OpenAI-Beta': 'assistants=v2',
                        },
                    }
                );

                const finalResponse =
                    messageResponse.data.data[0].content[0].text.value;
                setMessages([
                    ...newMessages,
                    { sender: 'bot', text: finalResponse },
                ]);
            }
        } catch (error) {
            console.error('Error fetching chatbot response:', error);
            setMessages([
                ...newMessages,
                { sender: 'bot', text: 'Sorry, something went wrong.' },
            ]);
        }
        // try {
        //     const response = await axios.post("http://localhost:3001/chatbot", {
        //         userMessage: input,
        //     });

        //     setMessages([...newMessages, { sender: "bot", text: response.data.botResponse }]);
        // } catch (error) {
        //     console.error("Error fetching chatbot response:", error);
        //     setMessages([...newMessages, { sender: "bot", text: "Sorry, something went wrong." }]);
        // }

        setLoading(false); // ✅ Set loading to false after receiving response
    };

    return (
        <div className="chat-container">
            <h3 className="title">Chat with Assistant</h3>
            <div className="message-container">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={
                            msg.sender === 'user'
                                ? 'user-message'
                                : 'bot-message'
                        }
                    >
                        <span className="message-bubble">
                            {msg.sender === 'user' ? (
                                msg.text
                            ) : (
                                <ReactMarkdown>{msg.text}</ReactMarkdown>
                            )}
                        </span>
                    </div>
                ))}
                {loading && (
                    <div className="bot-message">
                        <span className="message-bubble thinking-bubble">
                            Thinking{thinkingDots}
                        </span>
                    </div>
                )}
                {/* ✅ Empty div that we scroll to */}
                <div ref={messagesEndRef} />
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Ask me something..."
                    className="input"
                    disabled={loading} // ✅ Prevents input while loading
                />
                <button
                    onClick={sendMessage}
                    className="button"
                    disabled={loading}
                >
                    {loading ? '...' : 'Send'}{' '}
                    {/* ✅ Button updates while loading */}
                </button>
            </div>
        </div>
    );
};

export default Chatbot;
